<template>
  <div class="buyload">
    <p class="path">Frequently Asked Questions > TayoCash Services > Buy Load</p>
    <h3>Buy Load</h3>
    <h4 id="bl1">How do I buy prepaid load and/or gaming credits using my TayoCash wallet?</h4>
    <div class="paragraph">
      <ol>
        <li>Open your TayoCash app and select <span class="vtxt-align">Buy Load</span>.</li>

        <li>Enter the <span class="vtxt-align">Mobile Number</span> you want to send load to or allow TayoCash to access your contacts so you may easily choose from your contact list.</li>

        <li>Enter the amount or select the load item/promo you wish to purchase and tap <span class="vtxt-align">Next</span>.</li>

        <li>You will be shown a summary of the purchase. Tap the <span class="vtxt-align">Confirm</span> button to proceed.</li>

        <li>You should instantly receive an SMS notification for your load purchase.</li>
      </ol>
    </div>

    <h4 id="bl2">I purchased prepaid load and/or gaming credits but I have entered an incorrect amount. What do I do?</h4>
    <div class="paragraph">
      <p>
        Unfortunately, we cannot refund load transactions once they have been processed. This is due to the agreement we have with our external merchants or partners. Please make sure to check if all your details are correct before proceeding with the buy load transaction.
      </p>
    </div>

    <h4 id="bl3">I purchased prepaid load and/or gaming credits but I have entered an incorrect mobile number. What do I do?</h4>
    <div class="paragraph">
      <p>
        Unfortunately, we cannot refund load transactions once they have been processed. This is due to the agreement we have with our external merchants or partners. Please make sure to check if all your details are correct before proceeding with the buy load transaction.
      </p>
    </div>

    <h4 id="bl4">I got charged after buying prepaid load/gaming credits but did not receive the prepaid load in my mobile number.
    What do I do?</h4>
    <div class="paragraph">
      <p>
        If you still did not receive the prepaid load, you may <router-link class="link" :to="{ path: '/helpcenter', hash: '#submitticket' }">submit a ticket</router-link> via the TayoCash <a class="link" href="/helpcenter">Help Center</a> in the app or website, or email us at <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a>.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'buyload'
}
</script>

<style scoped lang='scss'></style>
